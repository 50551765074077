import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Box,
  Button,
  Chip,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { AccountCircle, Logout } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AppBar, DrawerHeader, Main } from "../atoms";
import { useStore } from "effector-react";
import {
  $account,
  changeAuthorityEvent,
  changeDepartmentEvent,
  changeRoleEvent,
  getUserInfo,
  resetUserInfo,
} from "../../../../model/account";
import Cookies from "js-cookie";
import { NavLinks } from "../molecules";
import { useTranslation } from "react-i18next";
import { SelectUI } from "../../../../components/select";
import i18n from "i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoPng from "../../../../assets/media/svg-files/logo-gray.svg";
import DrawerBanner from "../../../../assets/media/image/dashboard-banner.png";
import { socket } from "../../../../services/socket";
import {
  IEventGetData,
  NoteInterface,
} from "../../../../services/types/socket";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { HeaderMenuNotice } from "./header-menu-notice";
import ProfilePopup from "../../../../components/profile-popup";
import { getCatRoutes } from "../../../../categories";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  children: JSX.Element;
}

const drawerWidth = 270;

export const Header: React.FC<Props> = ({ children }) => {
  const userData = useStore($account).$accountData.data;
  const validAuthority: string =
    userData?.authorities?.find((item) => item.id === userData?.authority_id)
      ?.title ?? "";
  const { t } = useTranslation();
  const location = useLocation();
  const roleId = Cookies.get("role_id");
  const departmentId = Cookies.get("department_id");
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorNoticeEl, setAnchorNoticeEl] =
    React.useState<null | HTMLElement>(null);
  const [lang, setLang] = useState(Cookies.get("lang") ?? "oz");
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState<"fadeIn" | "fadeOut">(
    "fadeIn"
  );
  const [comissionPopap, setCommissionPopup] = useState<boolean>(false);
  const [badgeNotice, setBadgeNotice] = useState<IEventGetData | null>(null);
  const [toaster, setToaster] = useState<{
    open: boolean;
    message: string;
    severity: "success";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const closeToaster = (): void => {
    setToaster({
      ...toaster,
      open: false,
    });
  };

  const openComission = (): void => {
    setCommissionPopup(true);
  };

  const closeComission = (): void => {
    setCommissionPopup(false);
  };

  useEffect(() => {
    if (userData?.room != null && userData?.authority_id != null) {
      socket.emit("joinRoom", {
        room: userData.room,
        authorityId: userData.authority_id,
        role_id: roleId,
        department_id: departmentId,
      });
      socket.on("eventGetData", (response: { data: IEventGetData }) => {
        setBadgeNotice(response.data);
      });

      socket.on(
        "eventGetNotificationData",
        (response: { data: NoteInterface }) => {
          // console.log("response", response);
          setToaster({
            open: true,
            message: response.data.title,
            severity: "success",
          });
        }
      );
    }

    return () => {
      socket.off("joinRoom");
      socket.off("eventGetData");
      socket.off("eventGetNotificationData");
    };
  }, [userData?.room]);

  useEffect(() => {
    if (location !== displayLocation) setTransitionStage("fadeOut");
  }, [location, displayLocation]);

  const changeLanguage = (e: SelectChangeEvent<any>): void => {
    const lang = e.target.value;
    Cookies.set("lang", lang);
    setLang(lang);
    getUserInfo(lang);
    void i18n.changeLanguage(lang);
  };

  useEffect(() => {
    void i18n.changeLanguage(lang);
  }, []);

  const menuOpen = Boolean(anchorEl);

  const noticeMenuOpen = Boolean(anchorNoticeEl);

  const endAnimation = (): void => {
    if (transitionStage === "fadeOut") {
      setTransitionStage("fadeIn");
      setDisplayLocation(location);
    }
  };

  const changeAuthority = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    changeAuthorityEvent(Number(event.target.value));
  };

  const changeComission = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    changeDepartmentEvent(Number(event.target.value));
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const openNotice = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorNoticeEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleNoticeClose = (): void => {
    setAnchorNoticeEl(null);
  };
  const openDrawer = (): void => {
    setOpen(!open);
  };
  const logOut = (): void => {
    Cookies.remove("token");
    Cookies.remove("department_id");
    Cookies.remove("role_id");
    Cookies.remove("authority_id");
    navigate("/");
    resetUserInfo();
  };

  const nav = getCatRoutes(badgeNotice);

  return (
    <>
      <Dialog
        open={comissionPopap}
        onClose={closeComission}
        aria-labelledby="responsive-dialog-title"
        sx={{
          ".MuiDialog-paper": {
            width: "1000px",
            maxWidth: "100%",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span />
          <IconButton onClick={closeComission}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
              "& .css-1dnre13-MuiTypography-root ": {
                fontSize: "14px !important",
              },
            }}
          >
            <FormControl
              sx={{
                mt: "10px",
              }}
            >
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
              >
                {t("authority")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="authority_id"
                value={userData?.authority_id}
                onChange={changeAuthority}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {userData?.authorities?.map((item) => (
                  <Box key={item.id}>
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label={item.title}
                      disabled={item.debtor}
                    />
                    {item.debtor && (
                      <Box
                        sx={{
                          color: "red",
                          marginLeft: "30px",
                        }}
                      >
                        ({t("debtor_must")})
                      </Box>
                    )}
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
              "& .css-1dnre13-MuiTypography-root ": {
                fontSize: "14px !important",
              },
            }}
          >
            <FormControl
              sx={{
                mt: "10px",
              }}
            >
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
              >
                {t("department")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="department_id"
                value={userData?.department_id}
                onChange={changeComission}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {userData?.authorities
                  .find((authority) => authority.id === userData.authority_id)
                  ?.departments?.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.id}
                      control={<Radio />}
                      label={item.title}
                      sx={{ width: "100%", marginBottom: "5px" }}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }}
            >
              {t("roles")}:
            </FormLabel>
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {userData?.roles?.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    color="info"
                    label={item.title}
                    className={userData.role_id === item.id ? "active" : ""}
                    onClick={() => changeRoleEvent(item.id)}
                    sx={{
                      marginBottom: "5px !important",
                      cursor: "pointer",
                      "&:hover, &.active": { background: "#300395" },
                    }}
                  />
                );
              })}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toaster.open}
        // autoHideDuration={3000}
        onClose={closeToaster}
      >
        <Alert
          onClose={closeToaster}
          severity={toaster.severity}
          sx={{ maxWidth: "50%", background: "#ffff", borderLeftWidth: "6px" }}
          icon={false}
          variant="outlined"
        >
          {t(toaster.message)}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <AppBar drawerwidth={drawerWidth} position="fixed" open={open}>
        {/* TODO: этот кусок для отображения уведомления в админке!!! */}
        {/* <Box */}
        {/*   sx={{ */}
        {/*     background: "red", */}
        {/*     padding: "5px 10px", */}
        {/*     position: "relative", */}
        {/*     overflow: "hidden", */}
        {/*     display: "flex", */}
        {/*     ".anime": { */}
        {/*       animation: "scroll 20s linear infinite", */}
        {/*       "@keyframes scroll": { */}
        {/*         from: { */}
        {/*           transform: "translateX(0)", */}
        {/*         }, */}
        {/*         to: { */}
        {/*           transform: " translateX(calc(-100% - 20px))", */}
        {/*         }, */}
        {/*       }, */}
        {/*       span: { */}
        {/*         whiteSpace: "nowrap", */}
        {/*         width: "100%", */}
        {/*         marginRight: "200px", */}
        {/*         transition: "all 0.1s ease-in-out", */}
        {/*       }, */}
        {/*     }, */}
        {/*   }} */}
        {/* > */}
        {/*   <div className="anime"> */}
        {/*     <span>{t("warning_text")}</span> */}
        {/*   </div> */}
        {/*   <div className="anime"> */}
        {/*     <span>{t("warning_text")}</span> */}
        {/*   </div> */}
        {/* </Box> */}
        <Toolbar
          sx={{
            // background: '#3D8361',
            color: "#000",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            sx={{ mr: 2 }}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              img: {
                height: "50px",
              },
              span: {
                color: "#282B90",
                textTransform: "uppercase",
              },
            }}
          >
            <Link to="/">
              <img src={LogoPng} alt="logo" />
            </Link>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                marginLeft: "30px",
                width: "300px",
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: t("header_title") }} />
            </Link>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
            }}
          >
            <Box
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "25px",
                  borderWidth: "2px",
                  color: "#282B90",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#282B90 !important",
                  borderWidth: "2px",
                },
                ".MuiSvgIcon-root": {
                  color: "#282B90",
                },
              }}
            >
              <SelectUI
                value={lang}
                options={[
                  {
                    title: "O'Z",
                    value: "oz",
                  },
                  {
                    title: "ЎЗ",
                    value: "uz",
                  },
                  {
                    title: "QR",
                    value: "qr",
                  },
                  {
                    title: "КР",
                    value: "kr",
                  },
                  {
                    title: "РУ",
                    value: "ru",
                  },
                ]}
                onChange={changeLanguage}
              />
            </Box>
            <IconButton onClick={openNotice} sx={{ marginLeft: "1.25em" }}>
              <Badge
                badgeContent={badgeNotice?.note_count_all ?? undefined}
                color="error"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorNoticeEl}
              id="notice-menu"
              open={noticeMenuOpen}
              onClose={handleNoticeClose}
              onClick={handleNoticeClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <HeaderMenuNotice />
            </Menu>
            <Button
              color="inherit"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                color: "#282B90",
                marginLeft: "0.25em",
                img: {
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  overflow: "hidden",
                  borderRadius: "50%",
                },
              }}
              onClick={handleClick}
            >
              {userData?.image != null ? (
                <img src={userData.image} alt="use" />
              ) : (
                <AccountCircle />
              )}
            </Button>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              ".MuiMenuItem-root": { maxWidth: "100%", width: "600px" },
            }}
          >
            <MenuItem onClick={openComission}>
              <Grid container sx={{ width: "600px" }}>
                <Grid
                  container
                  sx={{ marginBottom: "10px", borderBottom: "2px solid #eee" }}
                >
                  <Grid item xs={4}>
                    {t("authority")}:
                  </Grid>
                  <Grid item xs={8}>
                    {
                      userData?.authorities?.find(
                        (item) => item.id === userData.authority_id
                      )?.title
                    }
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ marginBottom: "10px", borderBottom: "2px solid #eee" }}
                >
                  <Grid item xs={4}>
                    {t("department")}:
                  </Grid>
                  <Grid item xs={8}>
                    {
                      userData?.authorities
                        ?.find((item) => item.id === userData.authority_id)
                        ?.departments.find(
                          (department) =>
                            department.id === userData.department_id
                        )?.title
                    }
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ marginBottom: "10px", borderBottom: "2px solid #eee" }}
                >
                  <Grid item xs={4}>
                    {t("role")}:
                  </Grid>
                  <Grid item xs={8}>
                    {
                      userData?.roles?.find(
                        (item) => item.id === userData.role_id
                      )?.title
                    }
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem
              sx={{
                justifyContent: "flex-end",
              }}
              onClick={logOut}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t("logout")}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: `url('${DrawerBanner}') #282B90 no-repeat`,
            backgroundSize: "cover",
            color: "#fff",
            "&::-webkit-scrollbar": {
              width: 0,
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            sx={{
              padding: "25px 0",
              display: "flex",
              flexDirection: "column",
              "b, span": {
                marginBottom: "15px",
                "&:last-child": {
                  margin: 0,
                },
              },
            }}
          >
            <b>{validAuthority}</b>
          </Box>
        </DrawerHeader>
        <Divider style={{ background: "#fff", margin: "0 10px 15px" }} />
        <ProfilePopup />
        <Divider style={{ background: "#fff", margin: "15px 10px 0" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1.25em",
            paddingBottom: "1.25em",

            a: {
              padding: "0.3em 1.20em",
              textDecoration: "none",
              transition: ".3s",
              display: "inline-flex",
              alignItems: "center",
              color: "#ffffff",
              svg: {
                marginRight: "15px",
              },
              "&:hover, &:focus, &.active": {
                background: "#AEF8E4",
                color: "#1C295C",
              },
            },
          }}
        >
          {nav.map((item, index) => {
            return <NavLinks {...item} key={index} />;
          })}
        </Box>
        <Box
          sx={{
            border: "1px solid #fff",
            padding: "10px",
            margin: "0 10px",
          }}
        >
          <Typography textAlign="center" marginBottom="10px">
            {t("text_in_agreement_building_call_center")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LocalPhoneIcon style={{ margin: "0 10px 10px 0" }} />
            <div>
              <Typography>71 238-42-37</Typography>
              <Typography marginBottom="10px">71 202 22 02</Typography>
            </div>
          </Box>
          <Typography textAlign="center">
            {t("extra_internal_numbers")}
          </Typography>
        </Box>
      </Drawer>
      <Main
        // className={`${transitionStage}`}
        onAnimationEnd={endAnimation}
        drawerwidth={drawerWidth}
        open={open}
      >
        <DrawerHeader />
        {children}
      </Main>
    </>
  );
};
