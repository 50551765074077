import { NavProps } from "../interfaces/utility";
import MonitorIcon from "@mui/icons-material/Monitor";
import React from "react";
import {
  CheckDocumentSvg,
  InternalDocumentSvg,
} from "../assets/media/svg-icons";

export const MonitoringSenateCategories: NavProps[] = [
  {
    title: "monitoring",
    link: "monitoring",
    icon: <MonitorIcon />,
    children: [
      {
        title: "main",
        link: "monitoring/main",
      },
      {
        title: "composition_councils",
        link: "monitoring/composition-councils",
      },
      {
        title: "quality_composition",
        link: "monitoring/quality-composition",
      },
      {
        title: "reports",
        link: "monitoring/reports",
      },
      {
        title: "signed_docs",
        link: "monitoring/doc",
      },
      {
        title: "council_decision",
        link: "monitoring/council-decision",
      },
      {
        title: "deputy_request",
        link: "monitoring/deputy-request",
      },
    ],
  },
  {
    title: "tasks",
    link: "tasks",
    icon: <InternalDocumentSvg />,
    children: [
      {
        title: "new",
        link: "tasks/new",
      },
      {
        title: "processing",
        link: "tasks/in-execution",
      },
      {
        title: "confirmation",
        link: "tasks/confirmation",
      },
      {
        title: "executed",
        link: "tasks/executed",
      },
      {
        title: "all",
        link: "tasks/all",
      },
    ],
  },
  {
    title: "instructions",
    link: "instructions",
    icon: <CheckDocumentSvg />,
  },
];
