export const actionTypes: Record<string, any> = {
  announcement_of_decisions: [
    {
      parent_id: "announcement_of_decisions",
      group_id: 3,
      document_type_id: 43,
      action: "total",
      action_id: null,
    },
    {
      parent_id: "announcement_of_decisions",
      group_id: 3,
      document_type_id: 43,
      action: "regulation_documents",
      action_id: null,
    },
    {
      parent_id: "announcement_of_decisions",
      group_id: 3,
      document_type_id: 43,
      action: "decisions",
      action_id: null,
    },
    {
      parent_id: "announcement_of_decisions",
      group_id: 3,
      document_type_id: 43,
      action: "not_public_decisions",
      action_id: null,
    },
  ],
  legal_expertise_decision: [
    {
      parent_id: "legal_expertise_decision",
      group_id: 3,
      document_type_id: 43,
      action: "total_legal_expertise_decision",
      action_id: null,
    },
    {
      parent_id: "legal_expertise_decision",
      group_id: 3,
      document_type_id: 43,
      action: "total_normative_decision",
      action_id: null,
    },
  ],
  normative_from_decision: [
    {
      parent_id: "normative_from_decision",
      group_id: 3,
      document_type_id: 43,
      action: "districts_and_cities",
      action_id: 140,
    },
    {
      parent_id: "normative_from_decision",
      group_id: 3,
      document_type_id: 43,
      action: "local_taxes",
      action_id: 103,
    },
    {
      parent_id: "normative_from_decision",
      group_id: 3,
      document_type_id: 43,
      action: "heat_and_electricity",
      action_id: 123,
    },
    {
      parent_id: "normative_from_decision",
      group_id: 3,
      document_type_id: 43,
      action: "other_powers",
      action_id: 142,
    },
    {
      parent_id: "normative_from_decision",
      group_id: 3,
      document_type_id: 43,
      action: "total",
      action_id: null,
    },
  ],
  decisions_made: [
    {
      parent_id: "decisions_made",
      group_id: 3,
      document_type_id: 43,
      action: "normative",
      action_id: null,
    },
    {
      parent_id: "decisions_made",
      group_id: 3,
      document_type_id: 43,
      action: "not_normative",
      action_id: null,
    },
    {
      parent_id: "decisions_made",
      group_id: 3,
      document_type_id: 43,
      action: "is_public",
      action_id: null,
    },
    {
      parent_id: "decisions_made",
      group_id: 3,
      document_type_id: 43,
      action: "open",
      action_id: null,
    },
    {
      parent_id: "decisions_made",
      group_id: 3,
      document_type_id: 43,
      action: "is_xdfu",
      action_id: null,
    },
    {
      parent_id: "decisions_made",
      group_id: 3,
      document_type_id: 43,
      action: "total",
      action_id: null,
    },
  ],
  issues_raised: [
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "report",
      action_id: 1,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "information",
      action_id: 2,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "discussions",
      action_id: 3,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "control_analysis",
      action_id: 4,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "approval_decisions",
      action_id: 5,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "governor_approval_decisions",
      action_id: 6,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "governor_cancellation_decisions",
      action_id: 7,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "council_cancellation_decisions",
      action_id: 8,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "other_issues",
      action_id: 9,
    },
    {
      parent_id: "issues_raised",
      group_id: 3,
      document_type_id: 49,
      action: "total",
      action_id: null,
    },
  ],
  number_of_reports_heard: [
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "total",
      action_id: null,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "governor",
      action_id: 1,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "deputy_governor",
      action_id: 2,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "sector_header",
      action_id: 5,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "finance",
      action_id: 6,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "internal_affairs",
      action_id: 8,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "ecology",
      action_id: 10,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "tax",
      action_id: 11,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "on_prosecutor",
      action_id: 31,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "health_core",
      action_id: 12,
    },
    {
      parent_id: "number_of_reports_heard",
      group_id: 3,
      document_type_id: 49,
      action: "other_issues",
      action_id: 13,
    },
  ],
  number_of_information_heard: [
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "total",
      action_id: null,
    },
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "justice",
      action_id: 3,
    },
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "young",
      action_id: 9,
    },
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "people_reception",
      action_id: 4,
    },
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "ombutsman",
      action_id: 14,
    },
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "chairmen_commission",
      action_id: 15,
    },
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "deputy_groups",
      action_id: 16,
    },
    {
      parent_id: "number_of_information_heard",
      group_id: 3,
      document_type_id: 49,
      action: "other_issues",
      action_id: 17,
    },
  ],
  cancelled_decisions: [
    {
      parent_id: "cancelled_decisions",
      group_id: 3,
      document_type_id: 49,
      action: "council_cancellation_decisions",
      action_id: null,
    },
    {
      parent_id: "cancelled_decisions",
      group_id: 3,
      document_type_id: 49,
      action: "governor_cancellation_decisions",
      action_id: null,
    },
    {
      parent_id: "cancelled_decisions",
      group_id: 3,
      document_type_id: 49,
      action: "total",
      action_id: null,
    },
  ],
  submitted_appeals: [
    {
      parent_id: "submitted_appeals",
      group_id: 1,
      document_type_id: 2,
      action: "total",
      action_id: null,
    },
    {
      parent_id: "submitted_appeals",
      group_id: 1,
      document_type_id: 2,
      action: "appeal_rejected",
      action_id: null,
    },
    {
      parent_id: "submitted_appeals",
      group_id: 1,
      document_type_id: 2,
      action: "appeal_explanation",
      action_id: null,
    },
    {
      parent_id: "submitted_appeals",
      group_id: 1,
      document_type_id: 2,
      action: "appeal_resolved",
      action_id: null,
    },
    {
      parent_id: "submitted_appeals",
      group_id: 1,
      document_type_id: 2,
      action: "appeal_process_of_execution",
      action_id: null,
    },
  ],
};
