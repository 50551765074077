import { HomeLayout } from "../app/layout";
import React, { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import RegistryPage from "../pages/registry";

const HomePage = lazy(async () => await import("../pages/home"));
const NewsPage = lazy(
  async () => await import("../pages/news/templates/news-page")
);
const AllNewsPage = lazy(
  async () => await import("../pages/news/templates/all-news")
);
// const SenatorsPage = lazy(async () => await import("../pages/senators"));

const DeputyKnowListPage = lazy(
  async () => await import("../pages/deputy-know")
);

const LegislationPage = lazy(async () => await import("../pages/legislation"));
const ActivityDocPage = lazy(
  async () => await import("../pages/activity-docs")
);
const AboutCouncilPage = lazy(
  async () => await import("../pages/about-council")
);

const SenatePage = lazy(async () => await import("../pages/senat-committee"));

const FiltersBlockPage = lazy(
  async () => await import("../pages/search-block")
);

const DocProtoShowPage = lazy(async () => await import("../pages/proto-doc"));
const DocProtoPage = lazy(async () => await import("../pages/proto"));

export const CLIENT_MAIN: RouteObject = {
  path: "/",
  element: <HomeLayout />,
  children: [
    {
      index: true,
      element: (
        <Suspense>
          <HomePage />
        </Suspense>
      ),
    },
    {
      path: "news",
      children: [
        {
          index: true,
          element: (
            <Suspense>
              <AllNewsPage />
            </Suspense>
          ),
        },
        {
          path: ":id",
          element: (
            <Suspense>
              <NewsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "doc",
      children: [
        {
          index: true,
          element: (
            <Suspense>
              <DocProtoPage />
            </Suspense>
          ),
        },
        {
          path: ":id",
          element: (
            <Suspense>
              <DocProtoShowPage />
            </Suspense>
          ),
        },
      ],
    },
    // {
    //   path: "senators",
    //   element: (
    //     <Suspense>
    //       <SenatorsPage />
    //     </Suspense>
    //   ),
    // },
    {
      path: "deputy-know",
      element: (
        <Suspense>
          <DeputyKnowListPage />
        </Suspense>
      ),
    },
    {
      path: "legislation",
      children: [
        {
          index: true,
          element: (
            <Suspense>
              <LegislationPage />
            </Suspense>
          ),
        },
        {
          path: "activity-docs/:id",
          element: (
            <Suspense>
              <ActivityDocPage />
            </Suspense>
          ),
        },
      ],
    },
    // {
    //   path: "legislation",
    //   element: (
    //     <Suspense>
    //       <LegislationPage />
    //     </Suspense>
    //   ),
    // },
    {
      path: "about-council",
      element: (
        <Suspense>
          <AboutCouncilPage />
        </Suspense>
      ),
    },
    {
      path: "senat-committee",
      element: (
        <Suspense>
          <SenatePage />
        </Suspense>
      ),
    },
    {
      path: "registry",
      element: (
        <Suspense>
          <RegistryPage />
        </Suspense>
      ),
    },
    {
      path: "search",
      element: (
        <Suspense>
          <FiltersBlockPage />
        </Suspense>
      ),
    },
  ],
};
