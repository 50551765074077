import React, { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";

const MainMonitoringPage = lazy(
  async () => await import("../pages/dashboard/monitoring/main")
);
const CompositionCouncils = lazy(
  async () => await import("../pages/dashboard/monitoring/composition-councils")
);
const UserProfilePage = lazy(
  async () => await import("../pages/dashboard/monitoring/profile")
);
const MonitoringReports = lazy(
  async () => await import("../pages/dashboard/monitoring/reports")
);
const MonitoringDocListPage = lazy(
  async () => await import("../pages/dashboard/monitoring/doc/list")
);
const MonitoringDocShowPage = lazy(
  async () => await import("../pages/dashboard/monitoring/doc/show")
);

const TaskShowDoc = lazy(
  async () => await import("../pages/dashboard/senate-tasks/show")
);
const QualityComposition = lazy(
  async () => await import("../pages/dashboard/monitoring/quality-composition")
);
const CouncilDesign = lazy(
  async () => await import("../pages/dashboard/monitoring/council-design")
);
const DeputyRequest = lazy(
  async () => await import("../pages/dashboard/monitoring/deputy-request")
);
const InstructionsPage = lazy(
  async () => await import("../pages/dashboard/instructions")
);

const TaskList = lazy(
  async () => await import("../pages/dashboard/senate-tasks/list/index")
);

const SenateTask = lazy(
  async () => await import("../pages/dashboard/create-new-doc/task")
);

const EditTaskDoc = lazy(
  async () => await import("../pages/dashboard/senate-tasks/edit")
);

export const MONITORING_SENATE_ROUTE: RouteObject[] = [
  {
    path: "monitoring",
    children: [
      {
        path: "doc",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <MonitoringDocListPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <MonitoringDocShowPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "main",
        element: (
          <Suspense>
            <MainMonitoringPage />
          </Suspense>
        ),
      },
      {
        path: "composition-councils",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <CompositionCouncils />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <UserProfilePage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "quality-composition",
        element: (
          <Suspense>
            <QualityComposition />
          </Suspense>
        ),
      },
      {
        path: "reports",
        element: (
          <Suspense>
            <MonitoringReports />
          </Suspense>
        ),
      },
      {
        path: "council-decision",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <CouncilDesign />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <MonitoringDocShowPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "deputy-request",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <DeputyRequest />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <MonitoringDocShowPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "tasks",
    children: [
      {
        index: true,
        element: <Navigate to="new" />,
      },
      {
        path: "new",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <TaskList />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <TaskShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditTaskDoc />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "senate-task",
            element: (
              <Suspense>
                <SenateTask />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "in-execution",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <TaskList />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <TaskShowDoc />
                  </Suspense>
                ),
              },
              // {
              //   path: "edit",
              //   element: (
              //     <Suspense>
              //       <EditTaskDoc />
              //     </Suspense>
              //   ),
              // },
            ],
          },
        ],
      },
      {
        path: "confirmation",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <TaskList />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <TaskShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "executed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <TaskList />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <TaskShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "all",
        element: (
          <Suspense>
            <TaskList />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "instructions",
    element: (
      <Suspense>
        <InstructionsPage />
      </Suspense>
    ),
  },
];
